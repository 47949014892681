<template>
  <div>
    <strong v-if="state.reference2ID > 0">Variant v{{ state.reference2ID }} Upload</strong>
    <strong v-else>Item Upload</strong>

    <br>

    <div class="form-group">
      <label>Item</label>
      <Typeahead ref="curbFileHandlerCurb" :disabled="state.reference2ID > 0" cdi_type="curb" v-bind:ID.sync="state.referenceID" />
    </div>
    <div class="form-group">
      <label>Type</label>
      <select class="form-control" v-model="state.fileTypeID">
        <option :value="$cdiVars.FILE_TYPE_THREED_DRWNG">
          3d Drawing
        </option>
        <option :value="$cdiVars.FILE_TYPE_ENGINEERING_FL">
          Engineering
        </option>
        <option :value="$cdiVars.FILE_TYPE_WELDING_FL">
          Welding File
        </option>
        <option :value="$cdiVars.FILE_TYPE_KIT_CURB_BRAKE_FL">
          Kit Curb Brake
        </option>
        <option :value="$cdiVars.FILE_TYPE_ASSEM_PARTLABEL_FL">
          Assembly Part Label
        </option>
        <option :value="$cdiVars.FILE_TYPE_FIELD_ASSEM_DRWNG">
          Field Assembly
        </option>
        <option :value="$cdiVars.FILE_TYPE_PRODUCTION_NOTES">
          Production Notes
        </option>
        <option :value="$cdiVars.FILE_TYPE_E_DRAWING">
          E-Drawing
        </option>
        <option v-if="!state.reference2ID" :value="$cdiVars.FILE_TYPE_CONFORMING_STAMP">
          Conforming Stamp
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { fileUploadStore } from "@/store/FileUpload.store";
import Typeahead from "@/components/utils/typeaheads/Typeahead";
export default {
  name: "Curb",
  components: {
    Typeahead
  },
  data() {
    return {
      state: fileUploadStore.state
    }
  },
  created() {
    fileUploadStore.initialize(this.$cdiVars.FILE_TYPE_THREED_DRWNG);
  },
  mounted() {
    this.$refs.curbFileHandlerCurb.$data.object = this.$appStore.state.fileUploadContextData.data.nomenclature;
  }
}
</script>

<style scoped>
</style>